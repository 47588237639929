import React, { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { Search } from "@mui/icons-material";
import { salesForce, salesForceEcom } from "../../redux/api/DummyJson";
import AddAttributesPopup from "../Popup/AddAttributesPopup";
import ProjectDetailPopup from "../Popup/ProjectDetailPopup";
import ProjectDetailPopup2 from "../Popup/ProjectDetailPopup2";
import AddTaskPopup from "../Popup/AddTaskPopup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Link } from "react-router-dom";
import { toggleOff, toggleOn, viewIcon } from "../../assets/img";
import ReasonPopup from "../Popup/ReasonPopup";

const Enterprise = () => {
  const [showDetailPopup, setShowDetailPopup] = useState(null);
  const [showAddTask, setShowAddTask] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonShow, setReasonShow] = useState(false);

  const closeDetailPopup = () => {
    setShowDetailPopup(null);
  };

  const onClickAddTask = () => {
    setShowAddTask(!showAddTask);
  };

  const showReasonPopupHander = () => setReasonShow(!reasonShow);

  const getReason = (value) => {
    setReason(value);
    showReasonPopupHander();
  };

  return (
    <div className="mt-4 ">
      {reasonShow && (
        <ReasonPopup
          showReasonPopupHander={showReasonPopupHander}
          reason={reason}
        />
      )}
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className=" d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0 mb-4">
          <input
            type="text"
            className="w-20 get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
            placeholder="Search for project"
          />
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Client Rep
              </option>
              <option value="All">All</option>
              <option value="In Active Reps">In Active Reps</option>
            </select>
          </button>
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Order Rep
              </option>
              <option value="All">All</option>
              <option value="In Active Reps">In Active Reps</option>
            </select>
          </button>
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Stage
              </option>
              <option value="All">All</option>
              <option value="Open Projects">Open Projects</option>
              <option value="Opportunity">Opportunity</option>
              <option value="Presentation">Presentation</option>
              <option value="Estimate">Estimate</option>
              <option value="Sales Order">Sales Order</option>
              <option value="Invoice">Invoice</option>
            </select>
          </button>
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Status
              </option>
              <option value="All">All</option>
              <option value="Open Projects">Active</option>
              <option value="Opportunity">DeActive</option>
            </select>
          </button>
        </div>
        <div className="d-flex ac-jc gap-3">
          <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <Search />
          </button>
          <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <CachedIcon />
          </button>
        </div>
      </div>
      <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
          Total Count : {"04"}
        </p>
      </div>
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100 text-nowrap text-center">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project ID
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Created Time
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Order Rep
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Client Rep
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project Value
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Status of Project
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project Partner
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project Lead Source
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project Type of Order
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project Location Name
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Organization Name
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Contact Name
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Lost Date
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Lost Reason
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Tags
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Status
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {salesForceEcom?.map((item, index) => {
              return (
                <tr className="">
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    {" "}
                    <Link
                      className="f3 primary1"
                      to={"/overview"}
                      target="_blank"
                    >
                      {item?.id}
                    </Link>
                  </td>
                  <td>{item?.created_at}</td>
                  <td>{item?.orderRep}</td>
                  <td>{item?.custRep}</td>
                  <td>{item?.overdue}</td>
                  <td>{item?.stage?.status}</td>
                  <td>
                    {item?.partner} {(index + 10) % 17}
                  </td>
                  <td>
                    {item?.project_lead_name} {index + 1}
                  </td>
                  <td>
                    {item?.project_type_of_order} {index + 12}
                  </td>
                  <td>
                    {item?.project_location_name} {index + 7}
                  </td>
                  <td>
                    {item?.organization_name} {index + 3}
                  </td>
                  <td>
                    {item?.contact_name} {index + 42}
                  </td>
                  <td className="text-nowrap">{item?.lost_date}</td>
                  <td>
                    <div className="d-flex ac-jc primary1">
                      <div
                        role={"button"}
                        onClick={() =>
                          getReason(index + 1 + ". " + item?.lost_reason)
                        }
                        className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>
                    </div>
                  </td>
                  <td>
                    {item?.tag} {index + 23}
                  </td>
                  {/* <td>
                    <div className=" fs-14 text-center">
                      <Link
                        className="f3 primary1"
                        to={"/overview"}
                        target="_blank"
                      >
                        {item?.project?.id}
                      </Link>
                      <p
                        className="f1 fs-13 gray"
                        // onClick={() => setShowDetailPopup(item)}
                      >
                        {item?.project?.company}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div
                      className=" fs-14 text-center"
                      // onClick={() => setShowDetailPopup(item)}
                    >
                      <p className="f3">{item?.stage?.order}</p>
                      <p className="f1 fs-13 gray">{item?.stage?.status}</p>
                    </div>
                  </td> */}
                  <td>
                    <div
                      className="d-flex ac-js"
                      // onClick={() => change(item?.id)}
                    >
                      {item?.status == 1 ? (
                        <div>
                          <img src={toggleOn} className="toggleOnDes" />
                        </div>
                      ) : (
                        <div>
                          <img src={toggleOff} className="toggleOnDes" />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
          {/* <tfoot>
            <tr className="text-nowrap">
              <th colspan="6" style={{ textAlign: "right" }}>
                Total :
              </th>
              <td>₹ 1,021,618</td>
              <td>₹ 5,994</td>
              <td>₹ 0</td>
              <td>₹ 271,733</td>
            </tr>
          </tfoot> */}
        </table>
      </div>
      {/* {showDetailPopup && (
  <ProjectDetailPopup
    item={showDetailPopup}
    toggleShowPopup={closeDetailPopup}
    onClickAddTask={onClickAddTask}
  />
)} */}

      {/* {showDetailPopup && (
        <ProjectDetailPopup2
          item={showDetailPopup}
          toggleShowPopup={closeDetailPopup}
          onClickAddTask={onClickAddTask}
        />
      )} */}

      {showAddTask && <AddTaskPopup toggleShowPopup={onClickAddTask} />}
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </div>
  );
};

export default Enterprise;
