import { Search } from "@mui/icons-material";
import {
  animatedImg,
  editIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../assets/img";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ViewCustComp from "../components/Ecommerce/ViewCustComp";
import RequestCustList from "../components/Customers/RequestCustList";
import { useEffect, useState } from "react";
import NonRegisteredReqList from "../components/Customers/NonRegisteredReqList";
import CachedIcon from "@mui/icons-material/Cached";
import { Col } from "react-bootstrap";
import CustomerContact from "./CustomerContact";
import CustomerAddress from "./CustomerAddress";
import CustomerProjects from "./CustomerProjects";
import CustomerFiles from "./CustomerFiles";
import CustomerNote from "./CustomerNote";
import CustomerDetails from "./CustomerDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  useAdminListCustomerReqMutation,
  useCustomerRejectProRequestMutation,
  useCustomerRequestListMutation,
  useCustomerRequestListViewNonMutation,
  useLazyCustomerListStatusQuery,
  useLazyCustomerRequestListTagsQuery,
  useLazyCustomerRequestListViewQuery,
  useRoleStoreMutation,
} from "../redux/api/api";
import toast from "react-hot-toast";
import RejectedRegisteredReqList from "../components/Customers/RejectedRegisteredReqList";

const ViewCustomer = () => {
  const location = useLocation();
  // RTK QUERY
  const [customerListApi] = useCustomerRequestListMutation();
  const [customerNonListApi] = useCustomerRequestListViewNonMutation();
  // const [status] = useLazyCustomerListStatusQuery();
  const [customerAdminApi] = useAdminListCustomerReqMutation();
  const [customerRejectProReqApi] = useCustomerRejectProRequestMutation();

  const [orderRep, setOrderRep] = useState([]);
  const [clientRep, setClientRep] = useState([]);
  const [tab, setTab] = useState(1);
  const [regList, setRegList] = useState(false);
  const [slide, setSlide] = useState(false);
  // const [listt, setListt] = useState({});
  const [data, setData] = useState([]);
  const [list, setList] = useState({});
  const [lists, setLists] = useState();
  const [nonRegList, setNonRegList] = useState(false);
  const [actionBtn, setActionBtn] = useState(false);
  const [detailEdit, setDetailsEdit] = useState(true);
  const [tabBtn, setTabBtn] = useState(4);
  const [pagenation, setPagination] = useState({});
  const [pagenationNon, setPaginationNon] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchValuee, setSearchValuee] = useState("");
  const [searchName, setSearchName] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [page1, setPage1] = useState(1);
  const [pageRow, setPageRow] = useState(10);
  const [pagenations, setPaginations] = useState({});
  const [pagenation1, setPagination1] = useState({});

  const [orderRef, setOrderRef] = useState("");
  const [clientRef, setClientRef] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [tagStatus, setTagStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [searchVale1, setSearchVale1] = useState("");
  const [pageRows, setPageRows] = useState(10);
  const [pageRow1, setPageRow1] = useState(10);
  const [orderReff, setOrderReff] = useState("");
  const [orderRef1, setOrderRef1] = useState("");
  const [clientReff, setClientReff] = useState("");
  const [clientRef1, setClientRef1] = useState("");
  const [orderStatuss, setOrderStatuss] = useState("");
  const [orderStatus1, setOrderStatus1] = useState("");
  const [tagStatuss, setTagStatuss] = useState("");
  const [tagStatus1, setTagStatus1] = useState("");
  const [searchNamee, setSearchNamee] = useState("");
  const [searchName1, setSearchName1] = useState("");
  const [filterr, setFilterr] = useState("");
  const [filter1, setFilter1] = useState("");
  const [nonRegLists, setNonReqList] = useState([]);
  const [nonRejectLists, setRejectReqList] = useState([]);

  useEffect(() => {
    if (location?.state?.tab) {
      setTab(location?.state?.tab);
    }
  }, []);

  const tabData = (item) => {
    setList(item);
    setSlide(true);
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setList((values) => ({ ...values, [name]: value }));
  };
  // console.log("LOCATION_____", location);

  useEffect(() => {
    setRegList(true);
  }, []);

  // console.log("orderRep", orderRep);

  //  GET LIST REGISTERED
  const getList = (
    event,
    row,
    orderRef,
    clientRef,
    orderStatus,
    tagStatus,
    searchName,
    filter
  ) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    setSearchValue(event);
    setPageRow(row);
    setOrderRef(orderRef);
    setClientRef(clientRef);
    setOrderStatus(orderStatus);
    setTagStatus(tagStatus);
    setSearchName(searchName);
    setFilter(filter);

    if (event?.length > 0) {
      formdata.append("search", event);
    }
    if (row) {
      formdata.append("rows", row);
    }
    if (orderRef) {
      formdata.append("order_rep", orderRef);
    }
    if (clientRef) {
      formdata.append("client_rep", clientRef);
    }
    if (orderStatus) {
      formdata.append("status", orderStatus);
    }
    if (tagStatus) {
      formdata.append("tag_id", tagStatus);
    }
    if (searchName?.length > 0) {
      formdata.append("search", searchName);
    }
    if (filter) {
      formdata.append("request_type", filter);
    }

    //  console.log('orderRef', orderRef);

    customerListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // console.log(res,"login")
          setData(res?.lists);
          setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };
  useEffect(() => {
    getList();
  }, [page]);

  //  GET LIST NON REGISTERED
  const getNonList = (
    event,
    row,
    orderReff,
    clientReff,
    orderStatuss,
    tagStatuss,
    searchNamee,
    filterr
  ) => {
    let formdata = new FormData();
    let params = `?page=${pages}`;

    setSearchVal(event);
    setPageRows(row);
    setOrderReff(orderReff);
    setClientReff(clientReff);
    setOrderStatuss(orderStatuss);
    setTagStatuss(tagStatuss);
    setSearchNamee(searchNamee);
    setFilterr(filterr);

    // console.log('event', event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }
    if (row) {
      formdata.append("rows", row);
    }
    if (orderReff) {
      formdata.append("order_rep", orderReff);
    }
    if (clientReff) {
      formdata.append("client_rep", clientReff);
    }
    if (orderStatuss) {
      formdata.append("status", orderStatuss);
    }
    if (tagStatuss) {
      formdata.append("tag_id", tagStatuss);
    }
    if (searchNamee?.length > 0) {
      formdata.append("search", searchNamee);
    }
    if (filterr) {
      formdata.append("request_type", filterr);
    }

    customerNonListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // console.log(res)
          setNonReqList(res?.lists);
          setPaginations(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };
  useEffect(() => {
    getNonList();
  }, [pages]);

  //  GET LIST NON REGISTERED
  const getRejectList = (
    event,
    row,
    orderReff,
    clientReff,
    orderStatuss,
    tagStatuss,
    searchNamee,
    filterr
  ) => {
    let formdata = new FormData();
    let params = `?page=${page1}`;

    setSearchVale1(event);
    setPageRow1(row);
    setOrderRef1(orderReff);
    setClientRef1(clientReff);
    setOrderStatus1(orderStatuss);
    setTagStatus1(tagStatuss);
    setSearchName1(searchNamee);
    setFilter1(filterr);

    // console.log('event', event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }
    if (row) {
      formdata.append("rows", row);
    }
    if (orderReff) {
      formdata.append("order_rep", orderReff);
    }
    if (clientReff) {
      formdata.append("client_rep", clientReff);
    }
    if (orderStatuss) {
      formdata.append("status", orderStatuss);
    }
    if (tagStatuss) {
      formdata.append("tag_id", tagStatuss);
    }
    if (searchNamee?.length > 0) {
      formdata.append("search", searchNamee);
    }
    if (filterr) {
      formdata.append("request_type", filterr);
    }

    customerRejectProReqApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // console.log(res)
          setRejectReqList(res?.lists);
          setPagination1(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };
  useEffect(() => {
    getRejectList();
  }, [page1]);

  // ADMIN LIST CUSTOMER REQ
  const getViewAdmin = () => {
    // console.log(123);
    customerAdminApi()
      .unwrap()
      .then((ress) => {
        // console.log('ress', ress);
        let profile = ress?.admins;
        setOrderRep(profile);
        setClientRep(profile);
        // setOrderReps(profile?.first_name);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    // if (location?.state?.data?.id) {
    getViewAdmin();
    // }
  }, []);
  // console.log("admin", data)

  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} alt="animate" className="searchManImg" />
      </div>

      {/* <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Request List
        </p>
        <div className="d-flex w-xs-100 mt-xs-5 position-relative">
          <input
            className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
          <Search className="gray f1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-19 fs-xl-19 fs-xxl-20 mt-1 searchVendor" />
        </div>
      </div> */}
      <div className="d-flex mt-2 ac-jb flex-wrap">
        <div className="d-flex mt-3 ">
          <button
            onClick={() => {
              setTab(1);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tab == 1
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                tab == 1 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Existing customer List
            </p>
          </button>
          <button
            onClick={() => {
              setTab(2);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tab == 2
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                tab == 2 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              New customer List
            </p>
          </button>
          <button
            onClick={() => {
              setTab(3);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tab == 3
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                tab == 3 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Rejected List
            </p>
          </button>
        </div>
      </div>

      {/* <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="w-100 d-flex flex-md-row flex-column">
          <div className=" d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
            <input
              type="text"
              className="w-15 get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
              placeholder="Name"
              onChange={(e) => getList(e.target.value, pageRow, orderRef, clientRef, orderStatus,tagStatus)}
              value={searchValue}
            />
            <button class="w-17 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
              <select className="bg-transparent border-0 py-3 primary1" onChange={(e) => getList(searchValue, pageRow, e.target.value,clientRef, orderStatus,tagStatus)}
                value={orderRef}>
                <option>Order Rep</option>
                {orderRep?.map((adm) => (
                  <option key={adm?.id} value={adm?.id}>
                    {adm?.first_name}
                  </option>
                )
                )}
              
              </select>
            </button>
            <button class="w-17 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
              <select className="bg-transparent border-0 py-3 primary1" onChange={(e) => getList(searchValue, pageRow,orderRef, e.target.value,orderStatus,tagStatus)}
                value={clientRef}>
                <option>Client Rep</option>
                {clientRep?.map((admins) => (
                  <option key={admins?.id} value={admins?.id}>
                    {admins?.first_name}
                  </option>
                ))}
          
              </select>
            </button>
            <button class="w-17 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
              <select className="bg-transparent border-0 py-3 primary1" onChange={(e) => getList(searchValue, pageRow,orderRef,clientRef, e.target.value,tagStatus)}
                value={orderStatus}>
                <option>Order Status</option>
                
                <option value="1">Requested</option>
                <option value="2">In progress</option>
                <option value="3">Collabrate</option>
                <option value="4"> Presentation ready</option>
                <option value="5">Presentation accept</option>
                <option value="6">Presentation reject</option>
                <option value="7">Estimation ready</option>
                <option value="8">Estimation accept</option>
                <option value="9">Estimation reject</option>
                <option value="10">Customer paid</option>
              </select>
            </button>
            <button class="w-17 mx-3 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 position-relative">
              <select className="bg-transparent border-0 py-3 primary1 position-absolute top-0" onChange={(e) => getList(searchValue, pageRow,orderRef,clientRef,orderStatus, e.target.value)}
                value={tagStatus}>
                <option>Tags</option>
               
                <option value="1">Event Tags</option>
                <option value="2">Icon Tags</option>
                <option value="3">Normal Tags</option>
              </select>
            </button>
          </div>
        </div>
        <div className="d-flex">
          <button className="mx-2 cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <Search />
          </button>
          <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <CachedIcon />
          </button>
        </div>
      </div> */}

      {/* {location?.pathname !== "/customer-request-list" && <ViewCustComp />} */}
      {tab == 1 && (
        <RequestCustList
          clientRep={clientRep}
          orderRep={orderRep}
          searchName={searchName}
          data={data}
          tabData={tabData}
          getList={getList}
          searchValue={searchValue}
          pagenation={pagenation}
          pageRow={pageRow}
          orderRef={orderRef}
          clientRef={clientRef}
          orderStatus={orderStatus}
          tagStatus={tagStatus}
          filter={filter}
          setPage={setPage}
          page={page}
        />
      )}
      {tab == 2 && (
        <NonRegisteredReqList
          clientRep={clientRep}
          orderRep={orderRep}
          searchNamee={searchNamee}
          data={nonRegLists}
          tabData={tabData}
          getNonList={getNonList}
          searchVal={searchVal}
          pagenation={pagenationNon}
          pageRows={pageRows}
          orderReff={orderReff}
          clientReff={clientReff}
          orderStatuss={orderStatuss}
          tagStatuss={tagStatuss}
          filterr={filterr}
          setPage={setPages}
          page={pages}
          pagenations={pagenations}
        />
      )}
      {tab == 3 && (
        <RejectedRegisteredReqList
          clientRep={clientRep}
          orderRep={orderRep}
          searchNamee={searchName1}
          data={nonRejectLists}
          tabData={tabData}
          getNonList={getRejectList}
          searchVal={searchVale1}
          pagenation={pagenation1}
          pageRows={pageRow1}
          orderReff={orderReff}
          clientReff={clientReff}
          orderStatuss={orderStatus1}
          tagStatuss={tagStatus1}
          filterr={filter1}
          setPage={setPage1}
          page={page1}
          pagenations={pagenation1}
        />
      )}
      <div
        style={{
          width: slide ? "50%" : "0px",
          transition: "0.2s all linear",
          position: "fixed",
          right: "0",
          top: 0,
          zIndex: 1111,
          background: "#fff",
        }}
      >
        <Col className="customer_list_slide px-2">
          <div className="d-flex justify-content-end">
            <div className="mx-2 mt-3">
              <div className="position-relative f3">
                <button
                  className="slide_btn"
                  onClick={() => setActionBtn(!actionBtn)}
                >
                  Actions
                  <KeyboardArrowDownIcon />
                </button>
                {actionBtn && (
                  <div className="action_btn">
                    <button
                      className="border-0 bg-white text-center"
                      onClick={() => {
                        setDetailsEdit(!detailEdit);
                        setActionBtn(!actionBtn);
                      }}
                    >
                      Edit Details
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="mx-2 ">
              <button
                // className="slide_btns"
                className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                style={{ backgroundColor: "#01a0b6" }}
                onClick={() => setSlide(false)}
              >
                Close
              </button>
            </div>
          </div>
          <div className="mt-3 mx-2 slide_head">
            <h5 className="f4 primary">{list?.name}</h5>
            <div className="d-flex">
              <p className="mt-2 f3">Last Activity: </p>
              <p className="mt-2 mx-1 f1">2023-10-11</p>
              <p className="mt-2 mx-2 primary1">CID #{list?.CustomerId}</p>
            </div>
            <div className="slide_tabs f2">
              <button
                className={
                  tabBtn == 4
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(4)}
              >
                Details
              </button>
              {/* <button
                className={
                  tabBtn == 1
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(1)}>
                Contacts
              </button> */}
              {list != "ecommerce" && (
                <button
                  className={
                    tabBtn == 2
                      ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                      : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                  }
                  onClick={() => setTabBtn(2)}
                >
                  Addresses
                </button>
              )}
              {list != "ecommerce" && (
                <button
                  className={
                    tabBtn == 3
                      ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                      : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                  }
                  onClick={() => setTabBtn(3)}
                >
                  Projects
                </button>
              )}
              <button
                className={
                  tabBtn == 7
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(7)}
              >
                Order Request ID
              </button>
              {list != "ecommerce" && (
                <button
                  className={
                    tabBtn == 5
                      ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                      : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                  }
                  onClick={() => setTabBtn(5)}
                >
                  Files
                </button>
              )}
              <button
                className={
                  tabBtn == 6
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(6)}
              >
                Notes
              </button>
            </div>
            {tabBtn == 1 && <CustomerContact list={list} />}
            {tabBtn == 2 && <CustomerAddress list={list} />}
            {tabBtn == 3 && <CustomerProjects list={list} />}
            {tabBtn == 4 && (
              <CustomerDetails
                list={list}
                detailEdit={detailEdit}
                setDetailsEdit={setDetailsEdit}
                handleChange={handleChange}
              />
            )}
            {tabBtn == 5 && <CustomerFiles list={list} />}
            {tabBtn == 6 && <CustomerNote list={list} />}
            {tabBtn == 7 && (
              <div className="d-flex flex-wrap mt-4 mx-3 details_tab link_projects">
                <Col lg={6} xl={6} xs={12}>
                  <h4 className="f3 fs-15 fs-xs-16 text-nowrap  primary mb-2 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                    Order Request ID
                  </h4>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16  text-nowrap fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                  >
                    <p>RMHQ001</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16  text-nowrap fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                  >
                    <p>RMHQ002</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16  text-nowrap fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                  >
                    <p>RMHQ003</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 text-nowrap  fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                  >
                    <p>RMHQ004</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 text-nowrap  fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                  >
                    <p>RMHQ005</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16 text-nowrap  fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16  primary1"
                  >
                    <p>RMHQ006</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 fs-15 fs-xs-16  text-nowrap fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16  primary1 "
                  >
                    <p>RMHQ007</p>
                  </Link>
                </Col>
                <Col lg={6} xl={6} xs={12}>
                  <h4 className="f3 fs-15 fs-xs-16 text-nowrap  primary mb-2 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                    Order Request Type
                  </h4>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16"
                  >
                    <p>Bulk Products</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                  >
                    <p>E-commerce</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                  >
                    <p>Preset Product</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                  >
                    <p>E-commerce</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                  >
                    <p>Bulk Products</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                  >
                    <p>Bulk Products</p>
                  </Link>
                  <Link
                    to={"#"}
                    className="w-20 f3 text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                  >
                    <p>RMHQ007</p>
                  </Link>
                </Col>
              </div>
            )}
          </div>
        </Col>
      </div>
    </div>
  );
};

export default ViewCustomer;
