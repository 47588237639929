import React, { useEffect, useState } from "react";
import {
  editIcon,
  noLogo,
  searchIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../assets/img";
import { attributeList, decorativeMethodList } from "../redux/api/DummyJson";
import { Search } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import AddAttributesPopup from "../components/Popup/AddAttributesPopup";
import ImageCroper from "../components/Popup/ImageCroper";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useAdminExportsMutation,
  useBoxCreateMutation,
  useBoxListMutation,
  useBoxUpdateMutation,
  useLazyBoxStatusQuery,
  useLazyBoxViewQuery,
  useLazyHsnNoPageListQuery,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const InventoryBoxPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [list, setList] = useState([]);
  const [listHSN, setListHSN] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});
  const [btn, setBtn] = useState(false);
  const [update, setUpdate] = useState({});

  const [name, setName] = useState("");
  const [model, setmodel] = useState("");
  const [length, setlength] = useState("");
  const [breadth, setbreadth] = useState("");
  const [height, setheight] = useState("");
  const [volumetric_weight, setvolumetric_weight] = useState("");
  const [per_day_cost, setper_day_cost] = useState("");
  const [hsn_id, sethsn_id] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [modelErr, setmodelErr] = useState(false);
  const [lengthErr, setlengthErr] = useState(false);
  const [breadthErr, setbreadthErr] = useState(false);
  const [heightErr, setheightErr] = useState(false);
  const [volumetric_weightErr, setvolumetric_weightErr] = useState(false);
  const [per_day_costErr, setper_day_costErr] = useState(false);
  const [hsn_idErr, sethsn_idErr] = useState(false);

  //  RTK QUERY
  const [listHSNApi] = useLazyHsnNoPageListQuery();
  const [listBoxApi] = useBoxListMutation();
  const [viewBoxApi] = useLazyBoxViewQuery();
  const [createBoxApi] = useBoxCreateMutation();
  const [editBoxApi] = useBoxUpdateMutation();
  const [statusBoxApi] = useLazyBoxStatusQuery();
  const [exportsApi] = useAdminExportsMutation();

  const toggleShowPopup = (type) => {
    setShowPopup(!showPopup);
    setName("");
    setmodel("");
    setlength("");
    setbreadth("");
    setheight("");
    setvolumetric_weight("");
    setper_day_cost("");
    sethsn_id("");
    setUpdate({});
    setNameErr(false);
    setmodelErr(false);
    setlengthErr(false);
    setbreadthErr(false);
    setheightErr(false);
    setvolumetric_weightErr(false);
    setper_day_costErr(false);
    sethsn_idErr(false);
    if (type) setPopupType(type);
  };

  const getHSN = () => {
    listHSNApi()
      .unwrap()
      .then((res) => {
        setListHSN(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getHSN();
  }, []);

  const getList = (event, row) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    listBoxApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.lists);
          setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const change = (id) => {
    statusBoxApi(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.data?.message || res?.message);
          getList();
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, [page]);

  const submitHandle = () => {
    if (
      name?.length == 0 ||
      model?.length == 0 ||
      length?.length == 0 ||
      volumetric_weight?.length == 0 ||
      breadth?.length == 0 ||
      per_day_cost?.length == 0 ||
      height?.length == 0 ||
      hsn_id?.length == 0
    ) {
      setNameErr(true);
      setmodelErr(true);
      setlengthErr(true);
      setvolumetric_weightErr(true);
      setbreadthErr(true);
      setper_day_costErr(true);
      setheightErr(true);
      sethsn_idErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", name);
      formdata.append("model", model);
      formdata.append("length", length);
      formdata.append("volumetric_weight", volumetric_weight);
      formdata.append("breadth", breadth);
      formdata.append("per_day_cost", per_day_cost);
      formdata.append("height", height);
      formdata.append("hsn_id", hsn_id);

      setBtn(true);
      dispatch(saveLoader(true));
      if (update?.id) {
        editBoxApi({ payload: formdata, id: update?.id })
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);

              dispatch(saveLoader(false));
              toggleShowPopup();
              setName("");
              setUpdate({});
              setNameErr(false);
              getList();
            } else {
              toast.error(res?.data?.message || res?.message);
            }
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        createBoxApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);
              dispatch(saveLoader(false));
              toggleShowPopup();
              setName("");
              setUpdate({});
              setNameErr(false);
              getList();
            } else {
              toast.error(res?.data?.message || res?.message);
            }
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };

  const getAttribute = (item) => {
    toggleShowPopup("edit");
    console.log("item", item);
    setName(item?.name);
    setbreadth(item?.breadth);
    setlength(item?.length);
    setheight(item?.height);
    setper_day_cost(item?.per_day_cost);
    setvolumetric_weight(item?.volumetric_weight);
    setmodel(item?.model);
    sethsn_id(item?.hsn?.id);
    setUpdate(item);
  };

  // EXPORT EXCEL
  const getAdminExports = () => {
    let formdata = new FormData();
    formdata.append("type", 23);
    exportsApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      {showPopup && (
        <div className="add-category-popup">
          <div style={{ width: "70%" }}>
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              {popupType === "edit" ? "Edit" : "Add"} Box
              <span>
                <button onClick={toggleShowPopup} className="cust-btn">
                  <HighlightOffIcon />
                </button>
              </span>
            </p>

            <div className="d-flex ac-jc add-content">
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Product<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <input
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      placeholder="Product"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    {name?.length == 0 && nameErr && (
                      <div className="d-flex gap-1  position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Enter Product</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Model<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <input
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      placeholder="Model"
                      onChange={(e) => setmodel(e.target.value)}
                      value={model}
                    />
                    {model?.length == 0 && modelErr && (
                      <div className="d-flex gap-1  position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Enter Model</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex ac-jc add-content">
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Length<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <input
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      placeholder="Length"
                      onChange={(e) => setlength(e.target.value)}
                      value={length}
                      type="number"
                    />
                    {length?.length == 0 && lengthErr && (
                      <div className="d-flex gap-1  position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Enter Length</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Volumetric Weight<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <input
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      placeholder="Volumetric Weight"
                      onChange={(e) => setvolumetric_weight(e.target.value)}
                      value={volumetric_weight}
                      type="number"
                    />
                    {volumetric_weight?.length == 0 && volumetric_weightErr && (
                      <div className="d-flex gap-1  position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Enter Volumetric Weight
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex ac-jc add-content">
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Breadth<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <input
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      placeholder="Breadth"
                      onChange={(e) => setbreadth(e.target.value)}
                      value={breadth}
                      type="number"
                    />
                    {breadth?.length == 0 && breadthErr && (
                      <div className="d-flex gap-1 position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Enter Breadth</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Per Day Cost<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <input
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      placeholder="Per Day Cost"
                      onChange={(e) => setper_day_cost(e.target.value)}
                      value={per_day_cost}
                      type="number"
                    />
                    {per_day_cost?.length == 0 && per_day_costErr && (
                      <div className="d-flex gap-1  position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Enter Per Day Cost</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex ac-jc add-content">
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Height<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <input
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      placeholder="Height"
                      onChange={(e) => setheight(e.target.value)}
                      value={height}
                      type="number"
                    />
                    {height?.length == 0 && heightErr && (
                      <div className="d-flex gap-1  position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Enter Height</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  HSN Code<span>*</span>
                </p>
                <div className="w-100 d-flex w-xs-100 mt-2 position-relative">
                  <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                    <select
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      onChange={(e) => sethsn_id(e.target.value)}
                      value={hsn_id}
                    >
                      <option hidden>Select</option>
                      {listHSN?.map((item, ind) => {
                        return (
                          <option value={item?.id} key={ind}>
                            {item?.code}
                          </option>
                        );
                      })}
                    </select>
                    {hsn_id?.length == 0 && hsn_idErr && (
                      <div className="d-flex gap-1  position-absolute">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Enter HSN Code</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex mt-3 justify-content-end m-2">
              <button
                onClick={submitHandle}
                disabled={btn}
                className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                  btn && "opacity-50"
                }`}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="dashRightView p-5 home_section trans">
        <div className="d-flex ac-jb mt-2 flex-wrap">
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            Box
          </p>
          <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
            <div className="d-flex">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show
              </p>
              <select
                className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
                onChange={(e) => getList(searchValue, e.target.value)}
                value={pageRow}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Entries
              </p>
            </div>
            <div className="d-flex flex-md-row flex-column">
              {/* <div className="d-flex mt-sm-3">
              <img src={searchIcon} className="searchiConImg" alt="icon" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Search"
              />
            </div> */}
              <div className="d-flex ac-jb flex-md-row flex-column">
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-4">
                  Total Count : {pagenation?.total}
                </p>
                <div
                  className="pointerView d-flex mt-4 me-3"
                  role={"button"}
                  onClick={getAdminExports}
                >
                  <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
                  <p
                    className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
                  >
                    Export
                  </p>
                </div>
                <div className="d-flex mt-sm-3 border-search mx-1">
                  <img
                    src={searchIcon}
                    alt="search"
                    className="searchiConImg mt-1"
                  />
                  <input
                    className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                    placeholder="Search"
                    onChange={(e) => getList(e.target.value, pageRow)}
                    value={searchValue}
                  />
                </div>
                <button
                  onClick={() => toggleShowPopup("add")}
                  className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add Box
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
          <table className="table-design-new" style={{ width: "100%" }}>
            <thead>
              <tr className="border-bottom w-100 text-nowrap">
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    S.No
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Product
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Model{" "}
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Dimensions in CMS
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Volumetric Weigth
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    HSN Code
                  </p>
                </th>

                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Status
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Action
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {index + 1}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.name}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.model}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.dimension}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.volumetric_weight}
                      </p>
                    </td>

                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.hsn?.code}
                      </p>
                    </td>

                    <td>
                      <div
                        className="d-flex ac-jc"
                        onClick={() => change(item?.id)}
                      >
                        {item?.status === 1 ? (
                          <div>
                            <img src={toggleOn} className="toggleOnDes" />
                          </div>
                        ) : (
                          <div>
                            <img src={toggleOff} className="toggleOnDes" />
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div
                        onClick={() => getAttribute(item)}
                        className="ms-2 p-2 bg-white rounded-2 d-flex justify-content-center"
                      >
                        <img src={editIcon} className="viewDes" alt="icon" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button
            className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              page == 1 && "opacity-50"
            }`}
            onClick={() => setPage(page - 1)}
            disabled={page == 1 ? true : false}
          >
            Previous
          </button>
          <button
            className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              page == pagenation?.last_page && "opacity-50"
            }`}
            onClick={() => setPage(page + 1)}
            disabled={page == pagenation?.last_page ? true : false}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default InventoryBoxPage;
