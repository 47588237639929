import React, { useEffect, useState } from "react";
import {
  editIcon,
  noLogo,
  profilePic,
  toggleOff,
  toggleOn,
  uploadIcon,
  viewIcon,
} from "../assets/img";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  adminData,
  categoryList,
  subCategoryList,
} from "../redux/api/DummyJson";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddCategoryPopup from "../components/Popup/AddCategoryPopup";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../components/Popup/ImageCroper";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useCategoryAddMutation,
  useCategoryEditMutation,
  useLazyCategoriesAllQuery,
  useLazyCategoriesQuery,
  useLazyCategoryStatusQuery,
  useLazyCategoryViewQuery,
} from "../redux/api/api";
import AddCategorySub from "./AddCategorySub";

const AddCategoryScreenTest = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pageType = location.state?.type;
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [showCategoryAdd, setShowCategoryAdd] = useState(false);
  const [showSubCategoryAdd, setShowSubCategoryAdd] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [editPopup, setEditPopup] = useState("");
  const [list, setList] = useState([]);
  const [listIndex, setListIndex] = useState("");
  const [subList, setSubList] = useState([]);
  const [multiple, setMutiple] = useState([]);
  const [data, setData] = useState({});
  const [image, setImage] = useState("");

  const [croperImage, setCropImage] = useState("");
  const [croper, setCroper] = useState(false);
  const [codeShow, setCodeShow] = useState("");
  const [statusCode, setStatusCode] = useState("");

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  useEffect(() => {
    if (croperImage?.length > 0) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setImage(file);
        });
    }
  }, [croperImage]);

  const onCategoryClick = (item, index) => {
    multiple.splice(0, multiple.length);
    setMutiple(multiple);
    setSelectedCat(item?.id);
    setParent_id(item?.id);

    setSelectedSubCat("");
    setSelectedId("");
    getView(item);

    // ADD MULTIPLE
    // setMutiple([0]);
    // addMultiple(item, index);
  };

  const onSubCatSelect = (item, ind, index) => {
    // multiple.splice(index, multiple?.length);
    // console.log("mult", multiple);
    setSelectedSubCat([index]?.item?.name);
    setSelectedId([index]?.item?.id);
    setParent_id([index]?.item?.id);

    if (index < multiple?.length - 1) {
      let temp = [...multiple];
      let slicer = temp.slice(0, index + 1);
      setMutiple(slicer);

      getView(item, ind, index, " ", slicer);
    } else {
      getView(item, ind, index);
    }

    // ADD MULTIPLE
    // addMultiple(item, index);
  };

  const toggleCategShow = (type) => {
    setShowCategoryAdd(!showCategoryAdd);

    if (type == "edit") {
      setEditPopup(type);
    } else {
      setEditPopup(false);
    }
  };

  const toggleSubCategShow = (type, data, index, method) => {
    setShowSubCategoryAdd(!showSubCategoryAdd);

    setStatusCode(method);

    if (type == "edit") {
      setPopupType(type);
    } else {
      setPopupType(false);
    }

    setData({
      data,
      index,
    });
  };

  //  Add Parent Category
  const [addParentCat, setAddParentCat] = useState("");
  const [parent_id, setParent_id] = useState("");
  const [code, setCode] = useState("");
  const [volumetric_weight, setVolumetric_weight] = useState("");
  const [addCatId, setAddCatId] = useState("");
  const [addSubCat, setAddSubCat] = useState({});

  // RTK Query

  // const [listCategory] = useLazyCategoriesQuery();
  const [listCategory] = useLazyCategoriesAllQuery();
  const [AddCatogory] = useCategoryAddMutation();
  const [viewCategory] = useLazyCategoryViewQuery();
  const [editCategoryApi] = useCategoryEditMutation();
  const [statusChangeApi] = useLazyCategoryStatusQuery();

  const getList = (type) => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let finder = res?.categories?.filter(
            (i) => i?.id == location?.state?.data?.id
          );
          // console.log("finder", finder);
          setList(finder);
          setCode("");
          setAddParentCat("");
          if (parent_id && type !== "edit") {
            getView(parent_id);
          }

          if (selectedId && type !== "edit") {
            getView(selectedId);
          }
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  console.log("multiple", multiple);

  useEffect(() => {
    getList();
  }, []);

  const catSubmitHandler = () => {
    let formdata = new FormData();

    if (image?.name) {
      formdata.append("icon", image);
    }
    if (addParentCat) {
      formdata.append("name", addParentCat);
    }

    if (code) {
      formdata.append("code", code);
    }

    if (addCatId) {
      editCategoryApi({ id: addCatId, payload: formdata })
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            setAddCatId("");
            toggleCategShow();
            setAddParentCat("");
            setCropImage("");
            setImage("");
            getList("edit");
          }
        })
        .catch((err) => {
          console.log("err");
        });
    } else {
      AddCatogory(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            // NEW START
            navigate("/category-setting");
            // NEW END

            setAddCatId("");
            toggleCategShow();
            setAddParentCat("");
            setCropImage("");
            setImage("");
            getList("edit");
          }
        })
        .catch((err) => {
          console.log("err");
        });
    }
  };

  const catSubSubmitHandler = () => {
    let formdata = new FormData();

    if (addParentCat) {
      formdata.append("name", addParentCat);
    }

    if (data?.data?.id) {
      formdata.append("parent_id", data?.data?.id);
    }
    if (code) {
      formdata.append("code", code);
    }

    if (addSubCat?.id) {
      editCategoryApi({ id: addSubCat?.id, payload: formdata })
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            toggleSubCategShow();
            setData({});
            setAddSubCat({});
            getView(addSubCat?.category, " ", addSubCat?.index, "subSumit");
          }
        })
        .catch((err) => {
          console.log("err");
        });
    } else {
      AddCatogory(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            toggleSubCategShow();
            setData({});
            setAddSubCat({});
            getView(data?.data, " ", data?.index, "subSumit");
          }
        })
        .catch((err) => {
          console.log("err");
        });
    }
  };

  const getView = (item, ind, index, type, data) => {
    if (item?.id) {
      viewCategory(item?.id)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            setSubList(res);
            setAddParentCat("");
            setCode("");
            setVolumetric_weight("");
            // MULTIPLE
            let temp;
            if (data?.length > 0) {
              temp = [...data];
            } else {
              temp = [...multiple];
            }

            if (type == "subSumit") {
              let temX = res?.child_categories;

              let obj = {
                ...temp[index],
                child_categories: temX,
              };

              let final = multiple?.filter((i) => i?.category?.id !== item?.id);
              final.splice(index, 0, obj);

              setMutiple(final);
            } else {
              let finder = temp.indexOf(item?.id);
              if (temp?.includes(res)) {
                temp.slice(0, 1);
                temp.push(res);
              } else {
                temp.push(res);
              }

              setCodeShow(temp?.[0]?.category?.id);

              setMutiple(temp);
            }
            // addMultiple(item, index);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  // console.log("codeShow", codeShow);

  const base64ToFile = (base64String, fileName) => {
    let arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let converted = new File([u8arr], fileName, { type: mime });
    console.log("v", converted);
    return new File([u8arr], fileName, { type: mime });
  };

  // console.log("multiple", multiple);

  // POPUP FOR CATEGORY
  const editHandler = (item, ind) => {
    toggleCategShow("edit");
    setAddCatId(item?.id);
    setAddParentCat(item?.name);
    setImage(item?.icon);
  };

  // POPUP FOR SUB CATEGORY
  const editSubHandler = (category, item, ind, method) => {
    toggleSubCategShow("edit", "", "", method);
    setAddSubCat({ category: category, id: item?.id, index: ind });
    setAddParentCat(item?.name);
    setCode(item?.code);
    setVolumetric_weight(item?.volumetric_weight);
  };

  const statusChanger = (id, type, category, index) => {
    statusChangeApi(id)
      .unwrap()
      .then((res) => {
        if (type == "sub_cat") {
          getView(category, " ", index, "subSumit");
        } else {
          getList();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      {showCategoryAdd && (
        <div className="add-category-popup">
          <div>
            {" "}
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              {editPopup ? "Edit" : "Add"} Category
              <span>
                <button
                  onClick={() => {
                    setCropImage("");
                    setImage("");
                    setAddParentCat("");
                    setShowCategoryAdd(false);
                    setAddCatId("");
                  }}
                  className="cust-btn"
                >
                  <HighlightOffIcon />
                </button>
              </span>
            </p>
            <div className="d-flex ac-jc add-content">
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Category Name
                </p>
                <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                  <input
                    className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                    placeholder="Category Name"
                    value={addParentCat}
                    onChange={(e) => setAddParentCat(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-50 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Category Image
                </p>
                <div
                  className="w-70 ms-3"
                  onClick={() => toggleImagePopup()}
                  style={{ cursor: "pointer" }}
                >
                  {image ? (
                    <img
                      src={image?.name ? URL.createObjectURL(image) : image}
                      className="imgDashboard"
                    />
                  ) : (
                    <img src={noLogo} className="imgDashboard" />
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex mt-3 justify-content-end m-2">
              <button
                onClick={catSubmitHandler}
                className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      {showSubCategoryAdd && (
        <div className="add-category-popup">
          <div>
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              {popupType ? "Edit" : "Add"} Sub Category
              <span>
                <button
                  onClick={() => {
                    setAddSubCat({});
                    setAddParentCat("");
                    setCode("");
                    setVolumetric_weight("");
                    setShowSubCategoryAdd(false);
                  }}
                  className="cust-btn"
                >
                  <HighlightOffIcon />
                </button>
              </span>
            </p>

            <div className="d-flex ac-jc add-content">
              <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  Sub Category Name
                </p>
                <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                  <input
                    className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                    placeholder="Sub Category Name"
                    value={addParentCat}
                    onChange={(e) => setAddParentCat(e.target.value)}
                  />
                </div>
              </div>

              {statusCode == "show" && (
                <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                  <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                    With Code
                  </p>
                  <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                    <input
                      className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      placeholder="With Code"
                      onChange={(e) => setCode(e.target.value)}
                      value={code}
                    />
                  </div>
                </div>
              )}
            </div>
            {statusCode == "show" && (
              <div className="d-flex ac-jc add-content">
                <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
                  <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                    Volumetric Weight
                  </p>
                  <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                    <input
                      className="w-40 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                      placeholder="Volumetric Weight"
                      onChange={(e) => setVolumetric_weight(e.target.value)}
                      value={volumetric_weight}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="d-flex mt-3 justify-content-end m-2">
              <button
                onClick={catSubSubmitHandler}
                className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className="dashRightView p-5 home_section trans">
        <p
          className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary d-flex align-items-center gap-1"
          role={"button"}
          onClick={() => navigate(-1)}
        >
          {" "}
          <KeyboardBackspaceIcon />
          {pageType === "edit"
            ? "Edit Category"
            : pageType === "view"
            ? "View Category"
            : "Add Category"}
        </p>
        {/* <div className="scroller" style={{ width: "100%", overflow: "auto" }}> */}
        <div className="scroller">
          <div className="add-category">
            <div className="sub-category">
              <p className="f2 fs-16 primary">Parent Category</p>
              {pageType == "add" && (
                <button
                  onClick={() => toggleCategShow("add")}
                  className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add Parent Category
                </button>
              )}

              <div className="list-wrapper">
                {list?.map((item, index) => {
                  return (
                    <div
                      className={`list ${
                        item.id === selectedCat && "bg-soft-gray1"
                      } `}
                    >
                      <div>
                        <img
                          src={item?.icon ? item?.icon : noLogo}
                          className="categoryimg"
                          alt=""
                        />
                        <p className="text-center">
                          {item?.name?.length > 15
                            ? item?.name?.slice(0, 15) + "..."
                            : item?.name}
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={() => statusChanger(item?.id)}
                          disabled={pageType == "view" ? true : false}
                          className="border-0 bg-transparent"
                        >
                          <img
                            src={item?.status == 1 ? toggleOn : toggleOff}
                            className="toggleOnDes"
                            alt="icon"
                          />
                        </button>
                        {pageType !== "view" && (
                          <img
                            onClick={() => editHandler(item, index)}
                            src={editIcon}
                            className="viewDes"
                            alt="icon"
                          />
                        )}
                        <div
                          onClick={() => {
                            // setParent_id("");
                            onCategoryClick(item, index);
                          }}
                        >
                          {" "}
                          <ArrowForwardIosIcon className="arrow primary1" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {multiple?.map((item, index) => {
              return (
                <div className="sub-category">
                  <AddCategorySub
                    index={index}
                    selectedCat={selectedCat}
                    subList={item}
                    onSubCatSelect={onSubCatSelect}
                    selectedSubCat={selectedSubCat}
                    toggleSubCategShow={toggleSubCategShow}
                    statusChanger={statusChanger}
                    editSubHandler={editSubHandler}
                    codeShow={codeShow}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategoryScreenTest;
