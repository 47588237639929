import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { product, profilePic } from "../../assets/img";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import AddProjectPopup from "../Popup/AddProjectPopup";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AddFilesPopup from "../Popup/AddFilesOverview";
import NavbarHead from "../Sub_Layout/NavbarHead";
import IosShareIcon from "@mui/icons-material/IosShare";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { overview_input1, overview_input2 } from "../../redux/api/DummyJson";
import EditShippingPoppup from "../ShippingPopup/EditShippingPoppup";
import moment from "moment/moment";
import OverviewFiles from "./OverviewFiles";
import AddFilesPopupCopy from "../Popup/AddFilesOverviewCopy";
import Steper from "../Popup/Steper";

const OverView = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [btnChange, setBtnChange] = useState(0);
  const [btnFiles, setBtnFiles] = useState(0);
  const [comment, setComment] = useState("");
  const [commentBox, setCommentBox] = useState(null);
  const [currencyBtn, setCurrencyBtn] = useState(false);
  const [taskBtn, setTaskBtn] = useState(false);
  const [productBtn, setProductBtn] = useState(false);
  const [selectFiles, setSelectFiles] = useState(false);
  const [post, setPost] = useState("");
  const [actionBtn, setActionBtn] = useState(false);
  const [otherText, setOtherText] = useState([]);

  const showCommendBox = (ind) => {
    if (commentBox == ind) {
      setCommentBox(null);
    } else {
      setCommentBox(ind);
    }
  };

  const toggleShowPopup = () => {
    setCurrencyBtn(!currencyBtn);
  };

  const toggleShowPopups = () => {
    setSelectFiles(!selectFiles);
  };
  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };

  const addOtherInfo = (text) => {
    let temp = [...otherText];
    temp.push(text);
    setOtherText(temp);
  };

  console.log(
    "location",
    searchParams?.get("project"),
    searchParams?.get("project").split("_")[1],
    location
  );

  return (
    <>
      <div className="dashRightView2 overview_pages overflow-scroll ">
        <div className=" d-flex justify-content-end position-relative">
          <div className="action_overview mx-4">
            <NavbarHead onLinkShow={onLinkShow} />

            {actionBtn && (
              <div
                className="invisible-cont2"
                style={{ zIndex: 200 }}
                onClick={() => setActionBtn(false)}
              />
            )}
            {actionBtn && (
              <div className="action_btn ml-1">
                <button
                  className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                  onClick={() => navigate("/collaborate")}
                >
                  Collaborate
                </button>
                <button
                  className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                  onClick={() => navigate("/presentation")}
                >
                  Create Presentation
                </button>
                <button
                  className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                  onClick={() => navigate("/estimate")}
                >
                  Create Estimate
                </button>
                <button
                  className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                  onClick={() => navigate("/sales-order")}
                >
                  Create Sales Order
                </button>
                <button
                  className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                  onClick={""}
                >
                  View Portal
                </button>
              </div>
            )}
          </div>
        </div>
        {/* <EditShippingPoppup /> */}
        <div
          className={`${
            btnChange !== 1 && "flex_res_over_view"
          } d-flex as-jb gap-5 w-100 flex-column flex-md-row mt-5`}
        >
          <div className="d-flex overview_page w-100">
            <div className="w-100 px-0 px-md-3">
              <div className="d-flex ac-jb w-100">
                <div className="w-100 d-flex ac-js w-100">
                  <button
                    className={`${
                      btnChange == 0 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
                    onClick={() => setBtnChange(0)}
                  >
                    Activity
                  </button>
                  <button
                    className={`${
                      btnChange == 1 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
                    onClick={() => setBtnChange(1)}
                  >
                    Files
                  </button>
                </div>
                <div className="text-center">
                  {btnChange == 1 && (
                    <button class="position-relative cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                      Upload
                    </button>
                  )}
                </div>
              </div>
              <div className="over_view_tab  w-100">
                {btnChange == 1 && (
                  <>
                    {/* <div className="over_imgs mt-5">
                      <div className="over_img d-flex ac-jc rounded-2">
                        <img
                          src={product}
                          className="w-100 img_size3 object-fit-contain"
                        />
                        <div className="files_btn gap-1 d-flex mt-2 px-2">
                          <button className="">Edit</button>
                          <button className="">Delete</button>
                          <button className="">
                            <FileDownloadIcon />
                          </button>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <p className="im1">Jobs1.jpeg</p>
                        <p className="im2">Updated 14 October 20233333333333</p>
                      </div>
                    </div> */}
                    <div className="d-flex ae-jb w-100">
                      <button
                        className={`${
                          btnFiles == 0 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(0)}
                      >
                        Product Deck
                      </button>
                      <button
                        className={`${
                          btnFiles == 1 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(1)}
                      >
                        Accounting Documents
                      </button>
                      <button
                        className={`${
                          btnFiles == 2 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(2)}
                      >
                        Customer PO
                      </button>
                      <button
                        className={`${
                          btnFiles == 3 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(3)}
                      >
                        Material Information
                      </button>
                      <button
                        className={`${
                          btnFiles == 4 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(4)}
                      >
                        Artwork
                      </button>
                      <button
                        className={`${
                          btnFiles == 5 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(5)}
                      >
                        Notes and Attachment
                      </button>
                    </div>
                    {btnFiles == 0 && <OverviewFiles list={3} />}
                    {btnFiles == 1 && <OverviewFiles list={7} />}
                    {btnFiles == 2 && <OverviewFiles list={8} />}
                    {btnFiles == 3 && <OverviewFiles list={2} />}
                    {btnFiles == 4 && <OverviewFiles list={10} />}
                    {btnFiles == 5 && <OverviewFiles list={5} />}
                  </>
                )}

                {btnChange == 0 && (
                  <div className="w-100">
                    <div className="py-2 px-3 rounded-3 bg-lt-blue2 my-3 py-4 rounded-3">
                      <textarea
                        className="editBtn w-100 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        placeholder="Post a note about this project"
                        onChange={(e) => setPost(e.target.value)}
                      />
                      {productBtn && (
                        <div className="">
                          <img className="w-20" src={product} />
                        </div>
                      )}
                      {/* <div className="d-flex over_task_btn"> */}
                      <div className="d-flex justify-content-around justify-content-md-center gap-3 my-3 w-100">
                        <div className="d-flex as-jc gap-3 flex-column flex-sm-row">
                          <button
                            onClick={() => setTaskBtn(!taskBtn)}
                            style={productBtn ? { opacity: "0.3" } : {}}
                            className="cust-btn text-nowrap addbtn bg-primar me-0 text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mb-0"
                          >
                            <AccessTimeOutlinedIcon className="f3 me-1 text-light fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                            Task
                          </button>
                          <button
                            onClick={() => {
                              setTimeout(() => {
                                navigate("/product-library");
                              }, 1500);

                              setProductBtn(!productBtn);
                            }}
                            style={taskBtn ? { opacity: "0.3" } : {}}
                            className="text-nowrap cust-btn addbtn bg-primar me-0 text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mb-0"
                          >
                            <WorkOutlineOutlinedIcon className="f3 me-1 text-light fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                            Product
                          </button>
                        </div>
                        <div className="d-flex ae-jc gap-3 flex-column flex-sm-row">
                          <button
                            onClick={() => setSelectFiles(!selectFiles)}
                            className="text-nowrap cust-btn text-nowrap addbtn bg-primar me-0 text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 rounded mb-0"
                          >
                            <AttachmentOutlinedIcon className="f3 me-1 text-light fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                            File
                          </button>
                          <button
                            style={
                              post?.length > 0
                                ? { opacity: "1" }
                                : { opacity: "0.3" }
                            }
                            className="text-nowrap cust-btn addbtn bg-primar me-0 text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mb-0"
                          >
                            <IosShareIcon className="f3 me-1 text-light fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                            Post
                          </button>
                        </div>
                      </div>
                      {taskBtn && (
                        <div className="d-flex w-100 flex-wrap gap-3">
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Task Date
                            </p>
                            <input
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              type="date"
                              value={moment(new Date()).format("YYYY-MM-DD")}
                            />
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Task Deadline
                            </p>
                            <input
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              type="date"
                            />
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Project Associated
                            </p>
                            <select
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              disabled
                              value={2}
                            >
                              <option hidden>Select</option>
                              <option value={1}>Project 1</option>
                              <option value={2}>Project 2</option>
                            </select>
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Assigned By
                            </p>
                            <select
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              disabled
                              value={1}
                            >
                              <option hidden>Select</option>
                              <option value={1}>Roger</option>
                              <option value={2}>Sakthi</option>
                            </select>
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Department/ Team
                            </p>
                            <select
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              value={2}
                              disabled
                            >
                              <option hidden>Select</option>
                              <option value={1}>Department/ Team 1</option>
                              <option value={2}>Department/ Team 2</option>
                            </select>
                          </div>

                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              User
                            </p>
                            <select className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                              <option hidden>Select</option>
                              <option>User 1</option>
                              <option>User 2</option>
                            </select>
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Status
                            </p>
                            <select className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                              <option value={1}>Yet to start</option>
                              <option value={2}>In Process</option>
                              <option value={3}>Completed</option>
                            </select>
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Date of completion
                            </p>
                            <input
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              type="date"
                              readOnly
                            />
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Breach of SLA
                            </p>
                            <input
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              type="text"
                              placeholder="Breach of SLA"
                              value={"-2"}
                              readOnly
                            />
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Description
                            </p>
                            <textarea
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              type="text"
                              placeholder="Description"
                            />
                          </div>

                          {/* <div className="w-100 position-relative">
                            <p
                              className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                              style={{ marginLeft: "0px" }}
                            >
                              Task For
                            </p>
                            <div className="position-relative">
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                              />
                              <button className="drop_down cust-btn">
                                <KeyboardArrowDownIcon />
                              </button>
                            </div>
                            {/* {(
                          <div
                            className="invisible-cont2 z-0"
                          />
                        )} commended 
                            <div
                              className={`${"submenu_1"} d-none submenu_cont_1 overflow-scroll z-3`}
                            >
                              {overview_input2?.map((item) => {
                                return (
                                  <div className="d-flex hover-cust">
                                    <button className="px-2 cust-btn text-start py-1 w-100">
                                      {item?.list}
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div> */}
                          {/* <div className="flex-wrap">
                            <p
                              className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                              style={{ marginLeft: "0px" }}
                            >
                              Task For
                            </p>
                            <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                              <option>ODR</option>
                              <option>ODR 2</option>
                              <option>ODR 3</option>
                            </select>
                          </div> */}
                        </div>
                      )}
                    </div>
                    <div className="d-flex gap-3 ac-jc mb-3 px-0 px-md-4  w-100 flex-column flex-sm-row">
                      <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                        <div className="position-relative">
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          />
                          <button className="drop_down cust-btn">
                            <KeyboardArrowDownIcon />
                          </button>
                        </div>
                        {/* {(
                          <div
                            className="invisible-cont2 z-0"
                          />
                        )} */}
                        <div
                          className={`${"submenu_1"} d-none submenu_cont_1 overflow-scroll z-3`}
                        >
                          {overview_input1?.map((item) => {
                            return (
                              <div className="d-flex hover-cust">
                                <button className="px-2 cust-btn text-start py-1 w-100">
                                  {item?.list}
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                        <div className="position-relative">
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          />
                          <button className="drop_down cust-btn">
                            <KeyboardArrowDownIcon />
                          </button>
                        </div>
                        {/* {(
                          <div
                            className="invisible-cont2 z-0"
                          />
                        )} */}
                        <div
                          className={`${"submenu_1"} d-none submenu_cont_1 overflow-scroll z-3`}
                        >
                          {overview_input2?.map((item) => {
                            return (
                              <div className="d-flex hover-cust">
                                <button className="px-2 cust-btn text-start py-1 w-100">
                                  {item?.list}
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                        <input
                          placeholder="Search..."
                          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                      </div>
                    </div>
                    <div>
                      <ul>
                        <li className="d-flex flex-wrap">
                          <div className="d-flex ac-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                            <div className="d-flex-ac-jc mb-3 mb-md-0">
                              <img src={profilePic} className="sm_img_size" />
                            </div>
                            <div className="d-flex px-0 px-md-3 w-100">
                              <div className="mx-3 w-100">
                                <div className="d-flex ac-jb">
                                  <h5 className="author">
                                    <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                      Sample Man
                                    </span>
                                    <span className="author_name mx-2 f3 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                      — PRESENTATION (110204)
                                    </span>
                                  </h5>
                                </div>
                                <div>
                                  <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                                    Status changed from 'Open' to ' Open'
                                  </p>
                                </div>
                                <div>
                                  <div className="icon_com_pin">
                                    <p className="hours_ago gary f1 fs-xs-11 fs-sm-11 fs-md-11 fs-lg-12 fs-xl-12 fs-xxl-13">
                                      18 hours ago
                                    </p>
                                    <div
                                      className="d-flex ac-js gap-1"
                                      onClick={() => showCommendBox(null)}
                                    >
                                      <OverlayTrigger
                                        delay={{ hide: 450, show: 300 }}
                                        overlay={(props) => (
                                          <Tooltip {...props}>Comment</Tooltip>
                                        )}
                                        placement="top"
                                      >
                                        <CommentOutlinedIcon className="f3 primary fs-xs-14 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-17 cp" />
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        delay={{ hide: 450, show: 300 }}
                                        overlay={(props) => (
                                          <Tooltip {...props}>Pin</Tooltip>
                                        )}
                                        placement="top"
                                      >
                                        <PushPinOutlinedIcon className="f3 primary fs-xs-14 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-17 cp" />
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <DescriptionIcon className="f3 me-1 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-100 mt-3 py-2 px-2 px-sm-3 rounded-3 bg-lt-blue2">
                            <div className="d-flex mt-3">
                              <div className="d-flex ac-jc">
                                <img src={profilePic} className="sm_img_size" />
                              </div>
                              <div className="px-2 px-sm-3 w-100 d-flex">
                                <div className="">
                                  <div className="d-flex ac-jb">
                                    <h5 className="author ">
                                      <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                        New Man
                                      </span>
                                    </h5>
                                  </div>
                                  <div>
                                    <p className=" text_auth text-dark f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-12 fs-xl-13 fs-xxl-14">
                                      message received
                                    </p>
                                  </div>
                                  <div>
                                    <div className="icon_com_pin">
                                      <p className="hours_ago  gary f1 fs-xs-11 fs-sm-11 fs-md-11 fs-lg-12 fs-xl-12 fs-xxl-13">
                                        18 hours ago
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex mt-3 ac-jc align-items-sm-start justify-content-sm-start flex-column flex-sm-row">
                              <textarea
                                className="w-100 me-0 me-sm-3 rounded-1 p-2 w-70 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 editBtn"
                                placeholder="Comment on this message"
                                onChange={(e) => setComment(e.target.value)}
                              />
                              <div>
                                <button
                                  className={
                                    comment?.length > 0
                                      ? "cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                                      : "opacity-50 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mb-0"
                                  }
                                >
                                  Comment
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                        {[0, 1, 2, 3, 4].map((item, ind) => (
                          <li className="d-flex flex-wrap">
                            <div className="d-flex ac-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                              <div className="d-flex-ac-jc mb-3 mb-md-0">
                                <img src={profilePic} className="sm_img_size" />
                              </div>
                              <div className="d-flex px-0 px-md-3 w-100">
                                <div className="mx-3 w-100">
                                  <div className="d-flex ac-jb">
                                    <h5 className="author">
                                      <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                        Sample Man
                                      </span>
                                      <span className="author_name mx-2 f3 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                        — PRESENTATION (110204)
                                      </span>
                                    </h5>
                                  </div>
                                  <div>
                                    <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                                      Status changed from 'Open' to ' Open'
                                    </p>
                                  </div>
                                  <div>
                                    <div className="icon_com_pin">
                                      <p className="hours_ago gary f1 fs-xs-11 fs-sm-11 fs-md-11 fs-lg-12 fs-xl-12 fs-xxl-13">
                                        18 hours ago
                                      </p>
                                      <div
                                        className="d-flex ac-js gap-1"
                                        onClick={() => showCommendBox(null)}
                                      >
                                        <OverlayTrigger
                                          delay={{ hide: 450, show: 300 }}
                                          overlay={(props) => (
                                            <Tooltip {...props}>
                                              Comment
                                            </Tooltip>
                                          )}
                                          placement="top"
                                        >
                                          <CommentOutlinedIcon className="f3 primary fs-xs-14 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-17 cp" />
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          delay={{ hide: 450, show: 300 }}
                                          overlay={(props) => (
                                            <Tooltip {...props}>Pin</Tooltip>
                                          )}
                                          placement="top"
                                        >
                                          <PushPinOutlinedIcon className="f3 primary fs-xs-14 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-17 cp" />
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <DescriptionIcon className="f3 me-1 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-100 mt-3">
                              {commentBox == ind && (
                                <div className="d-flex">
                                  <textarea
                                    className="w-70  rounded-1 p-2 w-70 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 editBtn"
                                    placeholder="Comment on this message"
                                    onChange={(e) => setComment(e.target.value)}
                                  />
                                  <button
                                    className={
                                      comment?.length > 0
                                        ? "cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                                        : "opacity-50 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                                    }
                                  >
                                    Comment
                                  </button>
                                </div>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`${
              btnChange !== 1 ? "w-50 box_width" : "w-50   w-md-50"
            } px-0 px-md-3 `}
          >
            <h5 className="f3 fs-13 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 text-dark">
              Project Status
            </h5>
            <div className="d-flex ac-js gap-3 mt-3">
              <div className="over_pro_st m-0">
                <button className="cust-btn addbtn m-0  bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2">
                  SO
                </button>
              </div>
              <div className="flex-wrap">
                <p className="over_p_tag f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 m-0">
                  Presentation
                </p>
                <p className="over_open f1 fs-15 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-12 fs-xl-13 fs-xxl-14 primary1 m-0">
                  New
                </p>
              </div>
            </div>
            <h5 className=" f3 fs-13 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 text-dark my-3">
              Project Info
            </h5>
            <div className="py-2">
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Project ID
                </p>
                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="text"
                  value={"Pro012"}
                  readOnly
                />
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Partner
                </p>
                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2 mb-2">
                  <option>Partner 01</option>
                  <option>Partner 02</option>
                  <option>Partner 03</option>
                </select>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Lead sources
                </p>
                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2 mb-2">
                  <option>Lead sources 1</option>
                  <option>Lead sources 2</option>
                  <option>Lead sources 3</option>
                </select>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Type of Order
                </p>
                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2 mb-2">
                  <option>Type of Order 1</option>
                  <option>Type of Order 2</option>
                  <option>Type of Order 3</option>
                </select>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Location
                </p>
                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2 mb-2">
                  <option>Location 1</option>
                  <option>Location 2</option>
                  <option>Location 3</option>
                </select>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Organization Name
                </p>
                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="text"
                />
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Project Start date
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="date"
                  value={moment(new Date()).format("YYYY-MM-DD")}
                  readOnly
                  onChange={(e) => console.log("e", e.target.value)}
                />
              </div>

              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  In Hands Date
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="date"
                />
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Budget
                </p>
                <div className="d-flex">
                  <div className="editBtnSelect  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black  py-2 over_budgets">
                    <span className="over_budget_icon">₹</span>
                  </div>
                  <input
                    className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black  py-2"
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 0px" }}
                  />
                </div>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Initial Client Contact
                </p>
                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="text"
                  value={"Cli"}
                  readOnly
                />

                {/* <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                  <option>Cli</option>
                  <option>Cli 2</option>
                  <option>Cli 3</option>
                </select> */}
              </div>
              <div className="d-flex flex-wrap mt-3">
                <span className="w-100">example@gmail.com</span>
                <span>W: 7358144805</span>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Client Rep
                </p>

                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                  <option>ClR</option>
                  <option>ClR 2</option>
                  <option>ClR 3</option>
                </select>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Order Rep
                </p>

                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                  <option>ODR</option>
                  <option>ODR 2</option>
                  <option>ODR 3</option>
                </select>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Production Rep
                </p>

                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                  <option>Production Rep 1</option>
                  <option>Production Rep 2</option>
                  <option>Production Rep 3</option>
                </select>
              </div>
              <div className="">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Currency
                </p>
                <div className="d-flex ac-jb">
                  <span>INR</span>{" "}
                  <button
                    onClick={() => toggleShowPopup()}
                    class="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded opacity-50"
                  >
                    Change
                  </button>
                  {/* <button
                    className="mx-3 cust-btn addbtn ms-1  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-8 py-2 py-md-2 px-5 rounded"
                    onClick={() => toggleShowPopup()}
                  >
                    Change
                  </button> */}
                </div>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Tags
                </p>

                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                  <option>Select Tags</option>
                </select>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Expected Closing Date
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="date"
                />
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Project Email
                </p>
                <div className="d-flex">
                  <input
                    className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black py-2"
                    type="text"
                    style={{ borderRadius: "5px 0px 0px 5px" }}
                  />

                  <span className="editBtnSelect  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black  py-2 over_budget">
                    <ContentPasteOutlinedIcon />
                  </span>
                </div>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Project Template
                </p>

                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                  <option>Default Template</option>
                </select>
              </div>
              <div className="d-flex mt-3">
                <input type={"checkbox"} />
                <p style={{ marginLeft: "2%", fontWeight: "bold" }}>
                  Show Warning for Prop65
                </p>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Other Information
                </p>
                <select
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  onChange={(e) => addOtherInfo(e.target.value)}
                >
                  <option value={0}>Select Other Information</option>
                  <option value={1}>Other Information 1</option>
                  <option value={2}>Other Information 2</option>
                </select>
              </div>
              {otherText?.map((item, ind) => {
                return (
                  <div key={ind}>
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                      style={{ marginLeft: "0px" }}
                    >
                      {item == 0
                        ? "Other Information 1"
                        : item == 1
                        ? "Other Information 2"
                        : ""}
                    </p>

                    <input className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {currencyBtn && (
        <AddProjectPopup
          toggleShowPopup={toggleShowPopup}
          popupType={"currency"}
        />
      )}
      {selectFiles && <AddFilesPopupCopy toggleShowPopup={toggleShowPopups} />}
    </>
  );
};

export default OverView;
